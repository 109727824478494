<template>
    <div @click="toggleFullscreen">
        <video
            ref="videoPlayer"
            :src="videoUrl"
            autoplay
            loop
            playsinline
            controls
            muted
            @loadedmetadata="enterFullscreen"
        ></video>
    </div>
</template>
   
  <script>
export default {
    data() {
        return {
            videoUrl: '',
        }
    },
    mounted() {
        this.setVideoUrl()
    },
    methods: {
        setVideoUrl() {
            const videoName = this.$route.params.video
            this.videoUrl = `https://app.wellcomefit.ch/img/olma/${videoName}`
        },
        enterFullscreen() {
            const video = this.$refs.videoPlayer
            if (video.requestFullscreen) {
                video.requestFullscreen()
            } else if (video.webkitRequestFullscreen) {
                // for Safari
                video.webkitRequestFullscreen()
            } else if (video.mozRequestFullScreen) {
                // for Firefox
                video.mozRequestFullScreen()
            } else if (video.msRequestFullscreen) {
                // for IE/Edge
                video.msRequestFullscreen()
            }
        },
        toggleFullscreen() {
            const video = this.$refs.videoPlayer
            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else {
                this.enterFullscreen()
            }
        },
    },
    watch: {
        '$route.params.video': function () {
            this.setVideoUrl()
        },
    },
}
</script>
  
  <style scoped>
video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
</style>
   